import {Controller} from "@hotwired/stimulus"

export default class extends Controller {

    active(event) {
        fetch(event.params.url, {
            method: 'GET',
        }).then(
            response => {
                if (response.ok) {
                    return response.json()
                }
                return Promise.reject(response)
            }
        ).then(data => {
            if (data.error !== undefined) {
                console.log(data.error)
            } else if (data.string !== undefined && data.target !== undefined) {
                $(data.target).html(data.string);
            }
        }).catch(error => {
            console.warn(error.message)
        })
    }
}