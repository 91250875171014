import {Controller} from "@hotwired/stimulus"
// import datepicker from 'bootstrap-datepicker'

export default class extends Controller {
    static targets = ['copy']
    static values = {'url': String}

    copy() {
        let txt = $(this.copyTarget).html()
        txt = this.br2nl(txt)
        navigator.clipboard.writeText(txt)
    }

    br2nl (str) {
        let replaceStr = "\r\n";
        // let replaceStr = "\n";
        // Includes <br>, <BR>, <br />, </br>
        return str.replace(/<\s*\/?br\s*[\/]?>/gi, replaceStr);
    }

    update(event) {
        fetch(this.urlValue, {
            method: 'GET',
        }).then(
            response => {
                if (response.ok) {
                    return response.json()
                }
                return Promise.reject(response)
            }
        ).then(data => {
            if (data.error !== undefined) {
                console.log(data.error)
            } else if (data.keys !== undefined) {
                //console.log(data)
                $(this.copyTarget).html(data.keys);
                this.copy()
            }
        }).catch(error => {
            console.warn(error.message)
        })
    }
}