// noinspection JSUnresolvedReference

/**
 * Відповіді:
 *   - error  : запис в поле error блоку result
 *   - result : запис в поле result блоку result
 *   - response_error : запис в поле error блоку runs
 *   - form_reset : очищення (скидання) даних форми
 *
 * Всі відповіді пишуться незалежно
 *
 */

import {Controller} from "@hotwired/stimulus"

// noinspection JSCheckFunctionSignatures
export default class extends Controller {
    static values = {'runMessage': String}
    static targets = ['form', 'btnSend', 'btnActions']
    static outlets = ['form--form-runs', 'form--form-result', 'form--form']

    connect() {
        if (this.hasBtnSendTarget) {
            const btn = this.btnSendTarget
            this.element.addEventListener('submit', function (event) {
                event.preventDefault()
                event.stopPropagation()
                btn.click()
            })
        }
    }

    resetLock = true
    runMessage = ""

    // noinspection JSUnusedGlobalSymbols
    send(event) {
        event.preventDefault()
        event.stopPropagation()

        if (!this.formTarget.checkValidity()) {
            this.formTarget.reportValidity()
            return false;
        }

        // console.log(event.params)
        if (event.params['message'] !== undefined) {
            this.runMessage = event.params['message']
        } else {
            this.runMessage = this.runMessageValue
        }

        let action;
        if (event.currentTarget.hasAttribute('action')) {
            action = event.currentTarget.getAttribute('action')
        } else {
            action = this.formTarget.getAttribute('action')
        }

        const method = this.formTarget.getAttribute('method')

        if (method.toUpperCase() === 'GET') {
            this.formTarget.action = action
            this.formTarget.submit()
            return;
        }

        if (method.toUpperCase() === 'POST') {
            let data = new FormData(this.formTarget)
            this.htmlFieldLock()
            fetch(action, {
                method: method,
                body: data,
            }).then(response => {
                if (response.ok) {
                    return response.json()
                }
                return Promise.reject(response)
            }).then(data => {
                this.response(data)
            }).catch(error => {
                this.setError(error, 'send form')
                this.resetLock = true
            }).then(() => {
                if (this.resetLock) {
                    this.htmlFieldUnLock()
                }
            })
        }
    }

    next_step(action) {
        fetch(action, {
            method: 'GET'
        }).then(response => {
            if (response.ok) {
                return response.json()
            }
            return Promise.reject(response)
        }).then(data => {
            this.response(data)
        }).catch(error => {
            this.setError(error, 'js run sten')
            this.resetLock = true
        }).then(() => {
            if (this.resetLock) {
                //console.log('UnLock')
                this.htmlFieldUnLock()
            }
        })
    }

    response(data) {
        if (data.error !== undefined) {
            if (this.hasFormFormResultOutlet && this.formFormResultOutlet.errorTarget !== null) {
                $(this.formFormResultOutlet.errorTarget).html(data.error)
                this.formFormResultOutlet.errorTarget.classList.remove('d-none')
            }
        }
        if (data.result !== undefined) {
            if (this.hasFormFormResultOutlet && this.formFormResultOutlet.resultTarget !== null) {
                if (data.append !== undefined) {
                    $(this.formFormResultOutlet.resultTarget).append(data.result)
                } else if (data.prepend !== undefined) {
                    $(this.formFormResultOutlet.resultTarget).prepend(data.result)
                } else {
                    $(this.formFormResultOutlet.resultTarget).html(data.result)
                }
                this.formFormResultOutlet.resultTarget.classList.remove('d-none')
            }
        }
        if (data.response_error !== undefined) {
            if (this.hasFormFormRunsOutlet && this.formFormRunsOutlet.resultTarget !== null) {
                $(this.formFormResultOutlet.resultTarget).html(data.response_error)
                this.formFormResultOutlet.resultTarget.classList.remove('d-none')
            }
        }
        if (data.redirect !== undefined) {
            $(location).attr('href', data.redirect);
        }
        if (data.form_reset !== undefined && data.form_reset) {
            this.formTarget.reset()
        }
        if (data.reset_lock !== undefined) {
            this.resetLock = true
        }
        if (data.form_step !== undefined) {
            // поетапне виконання форми
            this.resetLock = false
            if (this.hasFormFormRunsOutlet && this.formFormRunsOutlet.resultTarget !== null) {
                $(this.formFormResultOutlet.resultTarget).prepend(data.form_step.info)
                this.formFormResultOutlet.resultTarget.classList.remove('d-none')
            }
            this.next_step(data.form_step.action)
        }
    }


    htmlFieldLock() {
        // console.log('Lock')
        if (this.hasFormFormRunsOutlet) {
            this.formFormRunsOutlet.show(this.runMessage)
        }
        if (this.hasFormFormResultOutlet) {
            this.formFormResultOutlet.clear()
        }
        Array.from(this.formFormOutlets).forEach(controller => {
            Array.from(controller.formTarget).forEach(element => {
                element.setAttribute("disabled", "disabled")
            })
        })
        Array.from(this.formTarget).forEach(element => {
            element.setAttribute("disabled", "disabled")
        })
        if (this.hasBtnActionsTarget) {
            this.btnActionsTargets.forEach(element => {
                element.setAttribute("disabled", "disabled")
            })
        }
    }


    htmlFieldUnLock() {
        if (this.hasFormFormRunsOutlet) {
            this.formFormRunsOutlet.hide()
        }
        Array.from(this.formFormOutlets).forEach(controller => {
            Array.from(controller.formTarget).forEach(element => {
                element.removeAttribute("disabled")
            })
        })
        Array.from(this.formTarget).forEach(element => {
            element.removeAttribute("disabled")
        })
        if (this.hasBtnActionsTarget) {
            this.btnActionsTargets.forEach(element => {
                element.removeAttribute("disabled")
            })
        }

    }

    setError(error, name = "") {
        if (this.hasFormFormRunsOutlet) {
            if (this.formFormRunsOutlet.errorTarget) {
                if (error.status !== undefined) {
                    this.formFormRunsOutlet.errorTarget.innerHTML = "Error \"" + name + "\": " + error.status
                } else {
                    this.formFormRunsOutlet.errorTarget.innerHTML = "Error \"" + name + "\": " + error
                }
            }
            this.formFormRunsOutlet.errorTarget.classList.remove('d-none')
        }
    }
}