import {Controller} from "@hotwired/stimulus";

export default class extends Controller {

    static targets = ["txt"]

    copy(event) {
        const target = event.currentTarget
        target.classList.add('btn-outline-danger')
        target.classList.remove('btn-outline-success')
        const text = $(this.txtTarget).text()
        let $temp = $("<textarea>");
        $("body").append($temp);
        $temp.html(text).select();
        navigator.clipboard.writeText($temp.val());
        $temp.remove();
        setTimeout(() => {
            target.classList.add('btn-outline-success')
            target.classList.remove('btn-outline-danger')
        }, 1000);
    }
}